import * as i from 'types';
import qs from 'qs';

import { getAuthenticationToken, removeAuthenticationTokens } from 'services/authentication';

// Manages content type headers and body parsing
export const contentTypeMiddleware: i. Middleware = (next) => async (request) => {
  const { body, headers = {} } = request.options;
  const contentType = headers['Content-Type'];

  // Managing content type makes sense only if there's a body
  if (!body) {
    return next(request);
  }

  // Default content type header and parse
  if (!contentType) {
    request.options.headers = {
      ...request.options.headers,
      'Content-Type': 'application/json',
    };
    request.options.body = JSON.stringify(body);
  }

  if (contentType === 'application/x-www-form-urlencoded') {
    request.options.body = qs.stringify(body);
  }

  return next(request);
};

// Adds token to headers for calls with authentication
export const authMiddleware: i.Middleware = (next) => async (requestOptions) => {
  // ToDo: Check wether withAuth should be default to true or false. Now on true but only
  // few calls need this. Is causing racing issues with calls that don't need auth because too much
  // tokens are being generated
  if (requestOptions.withAuth) {
    await getAuthenticationToken()
      .then((token) => {
        requestOptions.options.headers = {
          ...requestOptions.options.headers,
          'Authorization': token,
        };
      })
      .catch(() => {
        removeAuthenticationTokens();
      });
  }

  next(requestOptions);
};
