import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useLocation } from '@reach/router';

import { isBrowser } from 'services/browser';

const inBrowser = isBrowser();

export const useIndexRedirects = () => {
  const location = useLocation();
  const { navigate } = useI18next();

  // Check Zingfit redirect from reset password email
  React.useEffect(() => {
    if (inBrowser && location) {
      const { hash } = location;

      const url = '#/resetpassword/token/';

      if (hash.includes(url)) {
        navigate(`/newPassword?token=${hash.slice(url.length)}`);
      }
    }
  }, []);
};
