import * as React from 'react';

import { AuthenticationContext } from 'services/context/authenticationContext';

export const useAuthenticationContext = () => {
  const context = React.useContext(AuthenticationContext);

  if (!context) {
    throw new Error('Components should be rendered inside the AuthenticationProvider component');
  }

  return context;
};
