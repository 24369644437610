import accounting from 'accounting';

export const formatPrice: FormatPrice = (price, options = {}) => {
  const { hideSymbol, hideZeroDecimals } = options;
  const symbol = hideSymbol ? '' : '€';
  const decimal = hideZeroDecimals ? '' : ',-';

  return accounting
    .formatMoney(price, symbol, 2, '.', ',')
    .replace(',00', decimal);
};

type FormatPrice = (
  price: number, options?: { hideSymbol?: boolean; hideZeroDecimals?: boolean; }
) => string;
