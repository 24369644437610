import * as i from 'types';

export const memberShipOptions: SerieInputValues = [
  { label: 'Sepa', value: 'sepa' },
  { label: 'Creditcard', value: 'creditcard' },
];

export const classOptions: SerieInputValues = [
  { label: 'iDeal', value: 'ideal' },
  { label: 'Creditcard', value: 'creditcard' },
];

type SerieInputValues = {
  label: string;
  value: i.PaymentMethods;
}[];
