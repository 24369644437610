export const setLocalData = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    new Error(`Unable to save local data to storage on key: ${key}`);
  }
};

export const getLocalData = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    new Error(`Unable to get data from storage on key: ${key}`);
  }
};

export const removeLocalData = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    new Error(`Unable to remove data from storage on key: ${key}`);
  }
};
