import * as i from 'types';

const labels = {
  d: 'day',
  m: 'month',
  y: 'year',
};

export const getExpiryLabel = (unit: i.ExpirationUnits, amount: number) =>
  `${amount} ${labels[unit]}${amount > 1 ? 's' : ''}`;
