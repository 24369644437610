import * as i from 'types';
import { ActionType, action } from 'typesafe-actions';

const GET = 'order/GET';
const GET_SUCCESS = 'order/GET_SUCCESS';
const GET_FAILED = 'order/GET_FAILED';

const GET_ORDER = 'order/GET_ORDER';
const GET_ORDER_SUCCESS = 'order/GET_ORDER_SUCCESS';
const GET_ORDER_FAILED = 'order/GET_ORDER_FAILED';

const CREATE = 'order/CREATE';
const CREATE_SUCCESS = 'order/CREATE_SUCCESS';
const CREATE_FAILED = 'order/CREATE_FAILED';

const GET_PAYMENT_INFO = 'order/GET_PAYMENT_INFO';
const GET_PAYMENT_INFO_SUCCESS = 'order/GET_PAYMENT_INFO_SUCCESS';
const GET_PAYMENT_INFO_FAILED = 'order/GET_PAYMENT_INFO_FAILED';

const COMPLETE = 'order/COMPLETE';
const COMPLETE_SUCCESS = 'order/COMPLETE_SUCCESS';
const COMPLETE_FAILED = 'order/COMPLETE_FAILED';

const VERIFY_PAYMENT_INTENT = 'order/VERIFY_PAYMENT_INTENT';
const VERIFY_PAYMENT_INTENT_SUCCESS = 'order/VERIFY_PAYMENT_INTENT_SUCCESS';
const VERIFY_PAYMENT_INTENT_FAILED = 'order/VERIFY_PAYMENT_INTENT_FAILED';

const APPLY_DISCOUNT = 'order/APPLY_DISCOUNT';
const APPLY_DISCOUNT_SUCCESS = 'order/APPLY_DISCOUNT_SUCCESS';
const APPLY_DISCOUNT_FAILED = 'order/APPLY_DISCOUNT_FAILED';

const APPLY_GIFTCARD = 'order/APPLY_GIFTCARD';
const APPLY_GIFTCARD_SUCCESS = 'order/APPLY_GIFTCARD_SUCCESS';
const APPLY_GIFTCARD_FAILED = 'order/APPLY_GIFTCARD_FAILED';

const RESET = 'order/RESET';

export const orderActions = {
  get: () => action(GET),
  getSuccess: (order: i.Order) => action(GET_SUCCESS, order),
  getFailed: () => action(GET_FAILED),

  getOrder: () => action(GET_ORDER),
  getOrderSuccess: (order: i.Order) => action(GET_ORDER_SUCCESS, order),
  getOrderFailed: (error: any) => action(GET_ORDER_FAILED, error),

  create: () => action(CREATE),
  createSuccess: (order: i.Order) => action(CREATE_SUCCESS, order),
  createFailed: () => action(CREATE_FAILED),

  getPaymentInfo: () => action(GET_PAYMENT_INFO),
  getPaymentInfoSuccess: (paymentInfo: i.PaymentInfo) => action(GET_PAYMENT_INFO_SUCCESS, paymentInfo),
  getPaymentInfoFailed: () => action(GET_PAYMENT_INFO_FAILED),

  complete: () => action(COMPLETE),
  completeSuccess: () => action(COMPLETE_SUCCESS),
  completeFailed: () => action(COMPLETE_FAILED),

  verifyPaymentIntent: () => action(VERIFY_PAYMENT_INTENT),
  verifyPaymentIntentSuccess: () => action(VERIFY_PAYMENT_INTENT_SUCCESS),
  verifyPaymentIntentFailed: () => action(VERIFY_PAYMENT_INTENT_FAILED),

  applyDiscount: () => action(APPLY_DISCOUNT),
  applyDiscountSuccess: (order: i.Order) => action(APPLY_DISCOUNT_SUCCESS, order),
  applyDiscountFailed: () => action(APPLY_DISCOUNT_FAILED),

  applyGiftcard: () => action(APPLY_GIFTCARD),
  applyGiftcardSuccess: (order: i.Order) => action(APPLY_GIFTCARD_SUCCESS, order),
  applyGiftcardFailed: () => action(APPLY_GIFTCARD_FAILED),

  reset: () => action(RESET),
} as const;

const initialState: i.OrderState = {
  data: undefined,
  paymentInfo: undefined,
  error: false,
  loading: false,
};

export default (state = initialState, action: ActionType<typeof orderActions>): i.OrderState => {
  switch (action.type) {
    case CREATE:
    case GET:
    case GET_ORDER:
    case GET_PAYMENT_INFO:
    case COMPLETE:
    case APPLY_DISCOUNT:
    case APPLY_GIFTCARD:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case APPLY_GIFTCARD_SUCCESS:
    case APPLY_DISCOUNT_SUCCESS:
    case CREATE_SUCCESS:
    case GET_SUCCESS:
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false,
      };
    case COMPLETE_FAILED:
    case GET_PAYMENT_INFO_FAILED:
    case CREATE_FAILED:
    case GET_FAILED:
    case GET_ORDER_FAILED:
    case APPLY_DISCOUNT_FAILED:
    case APPLY_GIFTCARD_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case GET_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        paymentInfo: action.payload,
        error: false,
        loading: false,
      };
    case COMPLETE_SUCCESS:
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export const getOrderById: i.GetOrderById = (orderId) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    if (!orderId) return reject();
    dispatch(orderActions.getOrder());

    api.get({
      path: `/proxy/zingfit/orders/${orderId}`,
    }).then((order: i.Order) => {
      dispatch(orderActions.getOrderSuccess(order));
      resolve(order);
    }).catch((err: string) => {
      dispatch(orderActions.getOrderFailed(err));
      reject();
    });
  })
);

export const createOrder: i.CreateOrder = (seriesId) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    if (!seriesId) return reject();
    dispatch(orderActions.create());

    const body = { seriesId, confirmTerms: true };

    api.post({
      path: '/proxy/zingfit/orders/series',
      body: body,
    }).then((order: i.Order) => {
      dispatch(orderActions.createSuccess(order));
      resolve(order);
    }).catch(() => {
      dispatch(orderActions.createFailed());
      reject();
    });
  })
);

export const getOrderBySerie: i.GetOrderBySerie = (seriesId) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    if (!seriesId) return reject();
    dispatch(orderActions.get());

    api.get({
      path: `/proxy/zingfit/orders/series/${seriesId}`,
    }).then((order: i.Order) => {
      dispatch(orderActions.getSuccess(order));
      resolve(order);
    }).catch(() => {
      dispatch(orderActions.getFailed());
      reject();
    });
  })
);

export const getPaymentInfo: i.GetPaymentInfo = (orderId) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    if (!orderId) return reject();
    dispatch(orderActions.getPaymentInfo());

    api.get({
      path: `/proxy/zingfit/payments/${orderId}/paymentinfo`,
    }).then((paymentInfo: i.PaymentInfo) => {
      dispatch(orderActions.getPaymentInfoSuccess(paymentInfo));
      resolve(paymentInfo);
    }).catch(() => {
      dispatch(orderActions.getPaymentInfoFailed());
      reject();
    });
  })
);

export const completeOrder: i.CompleteOrder = (order, token) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    if (!order) return reject();
    dispatch(orderActions.complete());

    const body: i.CompleteOrderBody = {
      ...order.billingAddress,
      token,
      saveCard: true,
    };

    api.post({
      path: `/proxy/zingfit/payments/${order.id}/creditcard`,
      body,
    }).then((order: i.CompleteOrderResponse) => {
      dispatch(orderActions.completeSuccess());
      resolve(order);
    }).catch(() => {
      dispatch(orderActions.completeFailed());
      reject();
    });
  })
);

export const verifyPaymentIntent: i.VerifyPaymentIntent = (
  orderId, paymentIntentId,
) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(orderActions.verifyPaymentIntent());

    api.post({
      path: `/proxy/zingfit/payments/${orderId}/paymentintent`,
      body: {
        saveCard: false,
        paymentIntentId,
      },
    }).then((fulfillmentObject: i.CompleteOrderResponse) => {
      dispatch(orderActions.verifyPaymentIntentSuccess());
      resolve(fulfillmentObject);
    }).catch(() => {
      dispatch(orderActions.verifyPaymentIntentFailed());
      reject();
    });
  })
);

export const applyOrderDiscount: i.ApplyOrderDiscount = (orderId, promoCode) => (
  dispatch, getState, api,
) => (
  new Promise((resolve, reject) => {
    dispatch(orderActions.applyDiscount());

    api.post({
      path: `/proxy/zingfit/payments/${orderId}/promotion`,
      body: { promoCode },
    }).then((data: { order: i.Order }) => {
      dispatch(orderActions.applyDiscountSuccess(data.order));
      resolve(data.order);
    }).catch(() => {
      dispatch(orderActions.applyDiscountFailed());
      reject();
    });
  })
);

export const applyOrderGiftcard: i.ApplyOrderGiftcard = (orderId, code, pin) => (
  dispatch, getState, api,
) => (
  new Promise((resolve, reject) => {
    dispatch(orderActions.applyGiftcard());

    api.post({
      path: `/proxy/zingfit/payments/${orderId}/giftcard`,
      body: { code, pin },
    }).then((data: i.Order) => {
      dispatch(orderActions.applyGiftcardSuccess(data));
      resolve(data);
    }).catch(() => {
      dispatch(orderActions.applyGiftcardFailed());
      reject();
    });
  })
);
