import { useAuthenticationContext } from './useAuthenticationContext';

export const useAuthenticationUser = () => {
  const { state } = useAuthenticationContext();
  const { authenticated, loading, error } = state;
  const determined = typeof authenticated !== 'undefined';

  return {
    determined,
    authenticated: determined
      ? authenticated
      : false,
    loading,
    error,
  };
};
