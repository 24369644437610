import dayjs from 'dayjs';
import * as i from 'types';
import { action, ActionType } from 'typesafe-actions';

const GET = 'classes/GET';
const GET_SUCCESS = 'classes/GET_SUCCES';
const GET_FAILED = 'classes/GET_FAILED';

const GET_FILTERED = 'classes/GET_FILTERED';
const GET_FILTERED_SUCCESS = 'classes/GET_FILTERED_SUCCES';
const GET_FILTERED_FAILED = 'classes/GET_FILTERED_FAILED';

const SET_FILTER_OPTIONS = 'classes/SET_FILTER_OPTIONS';

const RESET = 'classes/RESET';

export const classesActions = {
  getClasses: () => action(GET),
  getClassesSuccess: (classes: i.Class[]) => action(GET_SUCCESS, classes),
  getClassesFailed: (error: string) => action(GET_FAILED, error),

  getFilteredClasses: () => action(GET_FILTERED),
  getFilteredClassesSuccess: (classes: i.Class[]) => action(GET_FILTERED_SUCCESS, classes),
  getFilteredClassesFailed: (error: string) => action(GET_FILTERED_FAILED, error),

  setFilterOptions: (filterOptions: i.FilterOptions) => action(SET_FILTER_OPTIONS, filterOptions),
  reset: () => action(RESET),
} as const;

const initialState: i.ClassesState = {
  data: undefined,
  error: false,
  loading: false,
  filterOptions: undefined,
  trainerList: undefined,
};

export default (state = initialState, action: ActionType<typeof classesActions>): i.ClassesState => {
  switch (action.type) {
    case GET_FILTERED:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_FILTERED_SUCCESS:
      return {
        ...state,
        trainerList: action.payload,
        loading: false,
        error: false,
      };
    case GET_FILTERED_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case GET:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
      };
    case GET_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case SET_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export const getClasses: i.GetClasses = (city: string, selectedDay: string) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(classesActions.getClasses());

    api.get({
      path: `/api/v2/${city}/classes?date=${selectedDay}`,
    }).then((classes: i.ClassesApiResponse) => {
      const classTypes = classes?.classTypes;
      dispatch(classesActions.setFilterOptions({
        classTypes: classTypes && classTypes.length > 0 ? [...classTypes, { id: 'event', name: 'Events' }] : [],
        instructors: classes?.instructors,
      }));
      dispatch(classesActions.getClassesSuccess(classes.classes));
      resolve(classes.classes);
    }).catch((error: string) => {
      dispatch(classesActions.getClassesFailed(error));
      reject();
    });
  })
);

export const getFilteredClasses: i.GetFilterdClasses = (
  ids: string[], filterKey: string,
) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    // generate array of three dates
    const today = dayjs().format('YYYY-MM-DD');
    const tomorrow = dayjs().add(2, 'day').format('YYYY-MM-DD');
    const dayAfterTomorrow = dayjs().add(3, 'day').format('YYYY-MM-DD');
    const dateArray = [today, tomorrow, dayAfterTomorrow];

    const scheduleData = dateArray.map((date) => {
      return api.get({
        path: `/api/v2/amsterdam/classes?date=${date}`,
      });
    });

    Promise.all(scheduleData)
      .then((classes) => {
        const instructorClassesList = classes.map((item) => item.classes)
          .flat()
          .filter((el) => ids.includes(el[filterKey]));
        dispatch(classesActions.getFilteredClassesSuccess(instructorClassesList));
        resolve(classes);
      })
      .catch((error: string) => {
        dispatch(classesActions.getFilteredClassesFailed(error));
        reject();
      });
  })
);
