import 'styled-components';

const theme = {
  colors: {
    primaryLight: '#8DB9D8',
    primary: Object.assign('#2916BA', {
      medium: '#2514A7',
      dark: '#211295',
    }),
    black: '#000000',
    gray: Object.assign('#989898', {
      lightest: '#E5E5E5',
      light: '#EEEEEE',
      medium: '#7f7880',
      dark: '#5B6770',
      background: '#F1F1F1',
      tableBackground: '#F8F8F8',
      line: '#B1B0B1',
    }),
    white: Object.assign('#FFFFFF', {
      opacity: 'rgba(255, 255, 255, .4)',
      off: 'rgba(255, 255, 255, .1)',
    }),
    green: {
      '500': '#A1D664',
      '700': '#78A545',
    },
    lightRed: '#F85F5F',
    darkRed: '#9E0001',
    red: '#FAB8AA',
    orange: '#FFAF23',
    special: '#A1D664',
  },
  fonts: {
    primary: 'FSHackney-regular, sans-serif',
  },
} as const;

export default theme;
