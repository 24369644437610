import 'css/fonts.css';

// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { Provider as StoreProvider } from 'react-redux';

import { store } from 'services';
import { AuthenticationProvider } from 'services/context/authenticationContext';

export const wrapRootElement = ({ element }) => (
  <StoreProvider store={store}>
    <AuthenticationProvider>
      {element}
    </AuthenticationProvider>
  </StoreProvider>
);

export default wrapRootElement;
