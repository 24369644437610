// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-concept-page-tsx": () => import("./../../../src/components/templates/conceptPage.tsx" /* webpackChunkName: "component---src-components-templates-concept-page-tsx" */),
  "component---src-components-templates-document-page-tsx": () => import("./../../../src/components/templates/documentPage.tsx" /* webpackChunkName: "component---src-components-templates-document-page-tsx" */),
  "component---src-components-templates-general-page-tsx": () => import("./../../../src/components/templates/generalPage.tsx" /* webpackChunkName: "component---src-components-templates-general-page-tsx" */),
  "component---src-components-templates-trainer-detail-page-tsx": () => import("./../../../src/components/templates/trainerDetailPage.tsx" /* webpackChunkName: "component---src-components-templates-trainer-detail-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-booking-tsx": () => import("./../../../src/pages/booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-class-tsx": () => import("./../../../src/pages/class.tsx" /* webpackChunkName: "component---src-pages-class-tsx" */),
  "component---src-pages-classes-tsx": () => import("./../../../src/pages/classes.tsx" /* webpackChunkName: "component---src-pages-classes-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-new-password-tsx": () => import("./../../../src/pages/newPassword.tsx" /* webpackChunkName: "component---src-pages-new-password-tsx" */),
  "component---src-pages-paymentsuccess-tsx": () => import("./../../../src/pages/paymentsuccess.tsx" /* webpackChunkName: "component---src-pages-paymentsuccess-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-request-password-tsx": () => import("./../../../src/pages/requestPassword.tsx" /* webpackChunkName: "component---src-pages-request-password-tsx" */),
  "component---src-pages-studio-detail-tsx": () => import("./../../../src/pages/studioDetail.tsx" /* webpackChunkName: "component---src-pages-studio-detail-tsx" */),
  "component---src-pages-studio-overview-tsx": () => import("./../../../src/pages/studioOverview.tsx" /* webpackChunkName: "component---src-pages-studio-overview-tsx" */),
  "component---src-pages-zf-tsx": () => import("./../../../src/pages/zf.tsx" /* webpackChunkName: "component---src-pages-zf-tsx" */)
}

