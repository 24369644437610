import dayjs from 'dayjs';

export const formatDate = (date: DateType) => date ? dayjs(date).format('DD-MM-YYYY') : '';
export const formatDateRaw = (date: DateType) => date ? dayjs(date).format('YYYY-MM-DD') : '';
export const formatDateShort = (date: DateType) => date ? dayjs(date).format('DD-MM-YY') : '';
export const formatTime = (date: DateType) => date ? dayjs(date).format('HH:mm') : '';
export const formatWrittenDate = (date: DateType) => date ? dayjs(date).format('DD MMMM') : '';
export const formatWrittenDateYear = (date: DateType) => date ? dayjs(date).format('DD MMMM YYYY') : '';
export const formatDay = (date: DateType) => dayjs(date).isSame(new Date(), 'day')
  ? 'Today'
  : dayjs(date).format('dddd');

export const formattedDateToRaw = (date: string | undefined) => {
  if (!date) return '';
  const dateArray = date.split('-');
  const year = dateArray[2];
  const month = dateArray[1];
  const day = dateArray[0];

  return `${year}-${month}-${day}`;
};

export const isSameOrAfterToday = (date: DateType) => {
  return dayjs(date).isSame(new Date()) || dayjs(date).isAfter(new Date());
};

export const isBeforeToday = (date: DateType) => {
  return dayjs(date).isBefore(new Date());
};

type DateType = Date | string | undefined | number;
