import * as i from 'types';
import { ActionType, action } from 'typesafe-actions';

export const classesDetailActions = {
  get: () => action('classesDetail/GET'),
  getSuccess: (classesDetail: i.Class) => action('classesDetail/GET_SUCCESS', classesDetail),
  getFailed: () => action('classesDetail/GET_FAILED'),

  getClassSeries: () => action('classesDetail/GET_SERIES'),
  getClassSeriesSuccess: (series: i.MeSerie[]) => action('classesDetail/GET_SERIES_SUCCESS', series),
  getClassSeriesFailed: () => action('classesDetail/GET_SERIES_FAILED'),

  getClassSpots: () => action('classesDetail/GET_SPOTS'),
  getClassSpotsSuccess: (spots: i.Spot[]) => action('classesDetail/GET_SPOTS_SUCCESS', spots),
  getClassSpotsFailed: () => action('classesDetail/GET_SPOTS_FAILED'),

  reset: () => action('classesDetail/RESET'),
} as const;

const initialState: i.ClassesDetailState = {
  data: undefined,
  series: undefined,
  spots: undefined,
  error: false,
  loading: false,
};

export default (state = initialState, action: ActionType<typeof classesDetailActions>): i.ClassesDetailState => {
  switch (action.type) {
    case 'classesDetail/GET_SERIES':
    case 'classesDetail/GET_SPOTS':
    case 'classesDetail/GET':
      return {
        ...state,
        error: false,
        loading: true,
      };
    case 'classesDetail/GET_SUCCESS':
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false,
      };
    case 'classesDetail/GET_SERIES_FAILED':
    case 'classesDetail/GET_SPOTS_FAILED':
    case 'classesDetail/GET_FAILED':
      return {
        ...state,
        error: true,
        loading: false,
      };
    case 'classesDetail/GET_SERIES_SUCCESS':
      return {
        ...state,
        series: action.payload,
        error: false,
        loading: false,
      };
    case 'classesDetail/GET_SPOTS_SUCCESS':
      return {
        ...state,
        spots: action.payload,
        error: false,
        loading: false,
      };
    case 'classesDetail/RESET':
      return initialState;
    default:
      return state;
  }
};

export const getClass: i.GetClass = (classId) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(classesDetailActions.get());

    api.get({
      path: `/api/v2/classes/${classId}`,
    }).then((res: i.Class) => {
      dispatch(classesDetailActions.getSuccess(res));
      resolve(res);
    }).catch(() => {
      dispatch(classesDetailActions.getFailed());
      reject();
    });
  })
);

export const getClassSpots: i.GetClassSpots = (classId) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(classesDetailActions.getClassSpots());

    api.get({
      path: `/proxy/zingfit/classes/${classId}/spots`,
    }).then((res: i.Spot[]) => {
      dispatch(classesDetailActions.getClassSpotsSuccess(res));
      resolve(res);
    }).catch(() => {
      dispatch(classesDetailActions.getClassSpotsFailed());
      reject();
    });
  })
);

export const getClassSeries: i.GetClassSeries = (classId) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(classesDetailActions.getClassSeries());

    api.get({
      path: `/proxy/zingfit/classes/${classId}/series`,
    }).then((res: i.MeSerie[]) => {
      dispatch(classesDetailActions.getClassSeriesSuccess(res));
      resolve(res);
    }).catch(() => {
      dispatch(classesDetailActions.getClassSeriesFailed());
      reject();
    });
  })
);
