import { isBrowser } from 'services';

export const lockBodyScroll = (lock: boolean) => {
  if (isBrowser()) {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (originalStyle === 'visible' && lock) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }

    return () => {
      if (isBrowser()) {
        document.body.style.overflow = originalStyle;
      }
    };
  }
};
