import * as React from 'react';
import { deviceSizes } from 'styles/utils';
import { useMediaQuery } from './useMediaQuery';

export const useDevice = (debounce = 500) => {
  const [determined, setDetermined] = React.useState<boolean>(false);
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const [isTablet, setIsTablet] = React.useState<boolean>(false);
  const [isDesktop, setIsDesktop] = React.useState<boolean>(false);
  const [mediaActive] = useMediaQuery(
    deviceSizes,
    debounce,
  );

  const isServer = typeof window === 'undefined';

  React.useEffect(() => {
    setDetermined(true);
    setIsMobile(!isServer && mediaActive === 'mobile');
    setIsTablet(!isServer && mediaActive === 'tablet');
    setIsDesktop(!isServer && mediaActive === 'desktop');
  }, [mediaActive]);

  return {
    isMobile,
    isTablet,
    isDesktop,
    determined,
  };
};

