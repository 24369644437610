import { isBrowser } from 'services';

export const triggerGtagEvent = (
  eventName: string,
  eventData: { [x: string]: unknown },
) => {
  if (isBrowser() && window.gtag) {
    window.gtag('event', eventName, {
      ...eventData,
    });
  }
};
