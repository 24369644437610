import * as i from 'types';
import * as React from 'react';

import { useDispatch } from 'services/hooks';
import { getAuthenticationToken } from 'services/authentication';
import { MeActions, getMe } from 'ducks/me';

export const AuthenticationContext = React.createContext<i.AuthenticationContextType | null>(null);

export const initialState: i.AuthenticationState = {
  authenticated: undefined,
  loading: false,
  error: false,
};

export const AuthenticationProvider: React.FC = ({ children }) => {
  const dispatchToStore = useDispatch();
  const [user, setUser] = React.useState(initialState);

  const dispatch = (userData: Partial<i.AuthenticationState>) => {
    const state = {
      ...user,
      ...userData,
    };

    setUser(state);
    return state;
  };

  React.useEffect(() => {
    const determineUser = async () => {
      dispatch({ loading: true });

      getAuthenticationToken()
        .then(() => {
          dispatch({
            authenticated: true,
            loading: false,
          });
        })
        .catch((error) => {
          dispatch({
            authenticated: false,
            loading: false,
          });
        });
    };

    determineUser();
  }, []);

  React.useEffect(() => {
    if (user.authenticated === true) {
      dispatchToStore(getMe());
    } else if (user.authenticated === false) {
      dispatchToStore(MeActions.resetMe());
    }
  }, [user.authenticated]);

  return (
    <AuthenticationContext.Provider value={{ state: user, dispatch }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
