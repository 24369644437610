import React from 'react';
import qs from 'qs';
import { navigate, useLocation } from '@reach/router';

export const useQueryParams = () => {
  const location = useLocation();

  const getQueryParams: UseQueryParamsReturnType = React.useMemo(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    Object.entries(queryParams).forEach(([key, value]) => {
      if (typeof value === 'string') queryParams[key] = value.split(',');
    });

    return queryParams;
  }, [location.search]);

  const setQueryParams = (params: UseSetQueryParamsProps) => {
    // remove empty queries
    const query: { [key: string]: string } = {};
    Object.keys(params).forEach((key) => {
      if (params[key]?.length === 0) return delete params[key];
      query[key] = params[key]?.join(',');
    });

    const search = qs.stringify(query);
    navigate(`${location.pathname}${search ? `?${search}` : ''}`);
  };

  return {
    queryParams: getQueryParams,
    setQueryParams,
  };
};

type UseQueryParamsReturnType = {
  [key: string]: any;
};

type UseSetQueryParamsProps = {
  [key: string]: string[];
};
