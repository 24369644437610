import * as i from 'types';
import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { membershipDataSelector } from 'selectors/classes';
import { useSelector } from 'services/hooks';

export const useGetMemberships = ({
  filterIds,
  isForPricingPage,
}: { filterIds: string[] | undefined, isForPricingPage: boolean }) => {
  const { language } = useI18next();
  const series = useSelector(membershipDataSelector);

  const membershipsData = useStaticQuery<GatsbyTypes.MembershipsQuery>(graphql`
    query Memberships {
      allContentfulComponentMemberships {
        edges {
          node {
            id
            benefits {
              id
              isText
              text
              title
              shortDescription
              longDescription
              checkbox
            }
            membershipIdAnually
            normalPriceAnnual
            membershipIdFlex
            normalPriceFlex
            membershipIdUpgrade
            isDefault
            isForPricingPageOnly
            title
            labelFlex
            labelAnnually
            labelUpgrade
            order
            node_locale
          }
        }
      }
    }
  `,
  );

  const memberships: i.ComponentMembershipBlock[] =
    membershipsData.allContentfulComponentMemberships.edges
      .map((data) => data.node)
      .filter((data) => filterIds ? (
        filterIds?.includes(data.id)
      ) : (
        data.isForPricingPageOnly === isForPricingPage
      ));
  const localizedMemberships: i.ComponentMembershipBlock[] = [];

  memberships
    .filter((membership) => membership.node_locale === language)
    .sort((a, b) => a.order - b.order)
    .forEach((membership) => {
      const annualSerie = series.find(({ id }) => id === membership.membershipIdAnually);
      const flexSerie = series.find(({ id }) => id === membership.membershipIdFlex);
      const upgradeSerie = series.find(({ id }) => id === membership.membershipIdUpgrade);

      if (annualSerie && flexSerie) {
        localizedMemberships.push({
          ...membership,
          id: membership.id,
          priceDifference:
            (flexSerie?.price?.amount || 0) - (annualSerie?.price?.amount || 0),
          annualSerie: {
            ...annualSerie,
            normalPrice: membership.normalPriceAnnual,
          },
          flexSerie: {
            ...flexSerie,
            normalPrice: membership.normalPriceFlex,
          },
          upgradeSerie,
        });
      }
    });

  const benefits: i.Benefit[] = [];

  localizedMemberships.forEach((membership) => (
    membership.benefits?.forEach((benefit) => {
      const hasBenefit = benefits
        ?.some(({ shortDescription }) => shortDescription === benefit.shortDescription);
      if (!hasBenefit) benefits.push(benefit);
    })
  ));

  return {
    benefits,
    localizedMemberships,
  };
};
