import * as i from 'types';
import { action, ActionType } from 'typesafe-actions';

const GET = 'sites/GET';
const GET_SUCCESS = 'sites/GET_SUCCESS';
const GET_FAILED = 'sites/GET_FAILED';

const SET_ACTIVE_SITE = 'sites/SET_ACTIVE_SITE';
const RESET = 'series/RESET';

export const sitesActions = {
  getSites: () => action(GET),
  getSitesSuccess: (sites: i.Site[]) => action(GET_SUCCESS, sites),
  getSitesFailed: (error: string) => action(GET_FAILED, error),

  setActiveSite: (site: string) => action(SET_ACTIVE_SITE, site),
  reset: () => action(RESET),
} as const;

const initialState: i.SitesState = {
  data: {
    sites: [],
  },
  activeSite: undefined,
  error: false,
  loading: {
    sites: false,
  },
};

export default (state = initialState, action: ActionType<typeof sitesActions>) => {
  switch (action.type) {
    case GET:
      return {
        ...state,
        loading: {
          ...state.loading,
          sites: true,
        },
        error: undefined,
      };
    case GET_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          sites: action.payload,
        },
        loading: {
          ...state.loading,
          sites: false,
        },
        error: undefined,
      };
    case GET_FAILED:
      return {
        ...state,
        data: {
          ...initialState.data,
        },
        loading: {
          ...state.loading,
          sites: false,
        },
        error: action.payload,
      };
    case SET_ACTIVE_SITE:
      return {
        ...state,
        activeSite: action.payload,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const getSites: i.GetSites = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(sitesActions.getSites());

    api.get({
      path: '/api/v2/sites',
    }).then((sites: i.SitesResponse) => {
      const cities = Object.entries(sites.NL.cities).map(([city, value]) => ({
        city,
        studios: value,
      }));
      dispatch(sitesActions.getSitesSuccess(cities));
      resolve(cities);
    }).catch((error: string) => {
      dispatch(sitesActions.getSitesFailed(error));
      reject();
    });
  })
);

