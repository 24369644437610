import * as i from 'types';
import { action, ActionType } from 'typesafe-actions';

const GET = 'series/GET';
const GET_SUCCESS = 'series/GET_SUCCESS';
const GET_FAILED = 'series/GET_FAILED';

const RESET = 'series/RESET';

export const seriesActions = {
  getSeries: () => action(GET),
  getSeriesSuccess: (series: i.Series) => action(GET_SUCCESS, series),
  getSeriesFailed: (error: string) => action(GET_FAILED, error),

  reset: () => action(RESET),
} as const;

const initialState: i.SeriesState = {
  data: {
    classes: [],
    starters: [],
    subscriptions: [],
    promotions: [],
  },
  error: false,
  loading: {
    series: false,
  },
};

export default (state = initialState, action: ActionType<typeof seriesActions>): i.SeriesState => {
  switch (action.type) {
    case GET:
      return {
        ...state,
        loading: {
          ...state.loading,
          series: true,
        },
        error: false,
      };
    case GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: {
          ...state.loading,
          series: false,
        },
        error: false,
      };
    case GET_FAILED:
      return {
        ...state,
        data: {
          ...initialState.data,
        },
        loading: {
          ...state.loading,
          series: false,
        },
        error: action.payload,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const getSeries: i.GetSeries = (city) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(seriesActions.getSeries());

    api.get({
      path: `/api/v2/series?city=${city}`,
    }).then((series: i.Series) => {
      dispatch(seriesActions.getSeriesSuccess(series));
      resolve(series);
    }).catch((error: string) => {
      dispatch(seriesActions.getSeriesFailed(error));
      reject();
    });
  })
);
