import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { api } from 'services/api';
import { isBrowser } from 'services';
import * as appReducers from 'ducks';

let middleware = applyMiddleware(thunk.withExtraArgument(api));
const reducers = combineReducers({ ...appReducers });

if (isBrowser() && process.env.GATSBY_APP_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
  middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
}

export const store = createStore(reducers, middleware);
