import * as i from 'types';

import config from './config';
import messages from './messages.json';

/**
 * Get user-friendly error message that will be displayed on the website.
 * @param reason Key of the error message
 * @returns Error message if key found; the default message otherwise
 */
export const getErrorMessage: i.GetErrorMessage = (reason) => {
  return messages[reason] || messages.default;
};

/**
 * Checks whether the request should skip response.
 * @param code Response status code
 * @returns `true` if the request should exit, `false` otherwise
 */
export const shouldExitRequest: i.ShouldExitRequest = (code: number) => {
  switch (code) {
    case 204:
    case 401:
    case 403:
      return true;
    default:
      return false;
  }
};

/**
 * Triggers custom error handler when provided.
 * @param errorConfig Message and meta information for message
 * @param apiError Backend error type with reason why it fails
 */
export const triggerErrorMessage: i.TriggerErrorMessage = (errorConfig, apiError) => {
  if (errorConfig.hide) return;

  if (errorConfig?.message) {
    config.onErrorMessage(errorConfig.message);
  } else if (apiError.reason && messages[apiError.reason]) {
    config.onErrorMessage(messages[apiError.reason]);
  }
};
