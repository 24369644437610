import { isBrowser } from 'services/isBrowser';

export const copyToClipboard = async (text: string) => {
  const clipboard = navigator.clipboard;
  const secureContext = isBrowser() && window.isSecureContext;

  if (clipboard && secureContext) {
    try {
      return await clipboard.writeText(text);

    } catch (err) {}
  }

};
