import * as i from 'types';

const apiConfig: i.ApiConfigType = {
  /**
   * Determine API URL
   * @see utils.ts
   */
  apiUrl: process.env.GATSBY_API_URL as string,

  /**
   * If the app isn't depended on authorization put this to false.
   * If this is turned off it won't use x-access-token in localStorage.
   * @see utils.ts
   */
  defaultWithAuth: true,

  /**
   * Trigger general error message for API failures.
   * @param message Generated error message
   * @see utils.ts
   */
  onErrorMessage: (message: string) => console.info(message),
};

export default apiConfig;
