import * as React from 'react';

export const useIntersection = (element: { current: Element | null }, threshold?: number | number[]) => {
  const [isVisible, setIsVisible] = React.useState(false);
  let observerRefValue: null | Element = null;

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      }, { threshold: threshold ?? 0 },
    );

    element?.current && observer.observe(element.current);
    observerRefValue = element?.current;

    return () => observerRefValue ? observer.unobserve(observerRefValue) : undefined;
  }, []);

  return isVisible;
};
