import * as i from 'types';

type MessageType = {
  [key: string]: string;
};

const messages: MessageType = {
  invalid_grant: 'message.error.invalidGrant',
  customer_not_found: 'message.error.customerNotFound',
  user_already_exists: 'message.error.userExists',
  not_enough_credits: 'message.error.notEnoughCredits',
  already_rated: 'message.error.alreadyRated',
  network_error: 'message.error.networkError',
  gift_card_not_found: 'message.error.giftCard.notFound',
  default: 'message.error.default',
};

/**
 * Zingfit API sends error messages does not come in a standardized format, we
 * need to parse them down to the copy we want to display
 */
const getErrorMessages = (error: i.ZingfitApiError): string => {
  const foundErrorMessage = messages[error?.error];

  // Return matching error message if found
  if (foundErrorMessage) return foundErrorMessage;

  // Special cases by Zingfit™
  if (/^(Network request failed)/.test(error?.message)) return messages.network_error;
  if (/^(Customer has already rated class)/.test(error?.rateClass)) return messages.already_rated;
  if (/^(Customer not found)/.test(error?.error)) return messages.customer_not_found;
  if (/^(The username already exists)$/.test(error?.username)) return messages.user_already_exists;
  if (/^(GiftCard)\s(.*)\s(.*)\s(not found)$/.test(error?.error)) return messages.gift_card_not_found;

  return messages.default;
};

export default getErrorMessages;
