import * as i from 'types';
import { ActionType, action } from 'typesafe-actions';

export const bookingActions = {
  get: () => action('booking/GET'),
  getSuccess: (bookings: i.Booking[]) => action('booking/GET_SUCCESS', bookings),
  getFailed: () => action('booking/GET_FAILED'),

  create: () => action('booking/CREATE'),
  createSuccess: () => action('booking/CREATE_SUCCESS'),
  createFailed: () => action('booking/CREATE_FAILED'),

  cancel: () => action('booking/CANCEL'),
  cancelSuccess: (classId: string) => action('booking/CANCEL_SUCCESS', classId),
  cancelFailed: () => action('booking/CANCEL_FAILED'),

  reset: () => action('booking/RESET'),
} as const;

const initialState: i.BookingState = {
  data: undefined,
  error: false,
  loading: false,
};

export default (state = initialState, action: ActionType<typeof bookingActions>): i.BookingState => {
  switch (action.type) {
    case 'booking/CANCEL':
    case 'booking/CREATE':
    case 'booking/GET':
      return {
        ...state,
        error: false,
        loading: true,
      };
    case 'booking/GET_SUCCESS':
      return {
        ...state,
        data: action.payload,
        error: false,
        loading: false,
      };
    case 'booking/CANCEL_FAILED':
    case 'booking/CREATE_FAILED':
    case 'booking/GET_FAILED':
      return {
        ...state,
        error: true,
        loading: false,
      };
    case 'booking/CREATE_SUCCESS':
      return {
        ...state,
        error: false,
        loading: false,
      };
    case 'booking/CANCEL_SUCCESS':
      return {
        ...state,
        data: state.data?.map((booking) => {
          if (booking.classId === action.payload) {
            return { ...booking, status: 'Cancelled' };
          }
          return booking;
        }),
        error: false,
        loading: false,
      };
    case 'booking/RESET':
      return initialState;
    default:
      return state;
  }
};

export const getBookings: i.GetBookings = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(bookingActions.get());

    api.get({
      path: '/proxy/zingfit/account/classes?size=100',
    }).then((bookings: { content: i.Booking[] }) => {
      dispatch(bookingActions.getSuccess(bookings.content));
      resolve(bookings.content);
    }).catch(() => {
      dispatch(bookingActions.getFailed());
      reject();
    });
  })
);

export const createBooking: i.CreateBooking = ({ classId, spotId, seriesItemId, isWaitList }) => (
  dispatch, getState, api,
) => (
  new Promise((resolve, reject) => {
    dispatch(bookingActions.create());

    const body: { seriesItemId: string; spotId?: string } = { seriesItemId };

    if (spotId) body.spotId = spotId;

    api.put({
      path: `/proxy/zingfit/classes/${classId}/${isWaitList ? 'waitlist' : 'spots'}`,
      body,
    }).then(() => {
      dispatch(bookingActions.createSuccess());
      resolve();
    }).catch(() => {
      dispatch(bookingActions.getFailed());
      reject();
    });
  })
);

export const cancelBooking: i.CancelBooking = ({ classId, attendanceId, lateCancel }) => (
  dispatch, getState, api,
) => (
  new Promise((resolve, reject) => {
    if (!attendanceId) return reject();
    dispatch(bookingActions.cancel());

    api.patch({
      path: `/proxy/zingfit/account/classes/${attendanceId}/cancel`,
      body: { lateCancel },
    }).then(() => {
      dispatch(bookingActions.cancelSuccess(classId));
      resolve();
    }).catch(() => {
      dispatch(bookingActions.cancelFailed());
      reject();
    });
  })
);
