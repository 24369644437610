import * as i from 'types';
import { action, ActionType } from 'typesafe-actions';

import getErrorMessage from 'services/getErrorMessage';

const GET = 'me/GET';
const GET_SUCCESS = 'me/GET_SUCCESS';
const GET_FAILED = 'me/GET_FAILED';

const GET_CLASSES = 'me/GET_CLASSES';
const GET_CLASSES_FAILED = 'me/GET_CLASSES_FAILED';
const GET_CLASSES_SUCCESS = 'me/GET_CLASSES_SUCCESS';

const EDIT = 'me/EDIT';
const EDIT_SUCCESS = 'me/EDIT_SUCCESS';
const EDIT_FAILED = 'me/EDIT_FAILED';

const RESET = 'me/RESET';

export const MeActions = {
  getMe: () => action(GET),
  getMeSuccess: (data: i.User) => action(GET_SUCCESS, data),
  getMeFailed: (error: string) => action(GET_FAILED, error),

  getUserClasses: () => action(GET_CLASSES),
  getUserClassesSuccess: (classes: i.Series[]) => action(GET_CLASSES_SUCCESS, classes),
  getUserClassesFailed: (error: string) => action(GET_CLASSES_FAILED, error),

  editMe: () => action(EDIT),
  editMeSuccess: (data: i.User) => action(EDIT_SUCCESS, data),
  editMeFailed: (error: string) => action(EDIT_FAILED, error),

  resetMe: () => action(RESET),
};

const initialState: i.MeState = {
  data: undefined,
  classes: undefined,
  loading: {
    getMe: false,
    editMe: false,
    getClasses: false,
  },
  error: undefined,
};

export default (state = initialState, action: ActionType<typeof MeActions>) => {
  switch (action.type) {
    case GET:
      return {
        ...state,
        loading: {
          ...state.loading,
          getMe: true,
        },
        error: undefined,
      };
    case GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: {
          ...state.loading,
          getMe: false,
        },
        error: undefined,
      };
    case GET_FAILED:
      return {
        ...state,
        data: undefined,
        loading: {
          ...state.loading,
          getMe: false,
        },
        error: action.payload,
      };
    case EDIT:
      return {
        ...state,
        loading: {
          ...state.loading,
          editMe: true,
        },
        error: undefined,
      };
    case EDIT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: {
          ...state.loading,
          editMe: false,
        },
        error: undefined,
      };
    case EDIT_FAILED:
      return {
        ...state,
        data: undefined,
        loading: {
          ...state.loading,
          editMe: false,
        },
        error: action.payload,
      };
    case GET_CLASSES:
      return {
        ...state,
        loading: {
          ...state.loading,
          getClasses: true,
        },
        error: undefined,
      };
    case GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload,
        loading: {
          ...state.loading,
          getClasses: false,
        },
        error: undefined,
      };
    case GET_CLASSES_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          getClasses: false,
        },
        error: action.payload,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const getMe: i.GetMe = () => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(MeActions.getMe());

    api.get({
      path: '/proxy/zingfit/account/me',
    }).then((response: i.User) => {
      dispatch(MeActions.getMeSuccess(response));
      resolve();
    }).catch((error: string) => {
      dispatch(MeActions.getMeFailed(error));
      reject();
    });
  })
);

export const editMe: i.EditMe = (body) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(MeActions.editMe());

    if (!body.password) delete body.password;

    api.put({
      path: '/proxy/zingfit/account/me',
      body,
    }).then((response: i.User) => {
      dispatch(MeActions.editMeSuccess(response));
      resolve(response);
    }).catch((error: string) => {
      dispatch(MeActions.editMeFailed(error));
      reject();
    });
  })
);

export const getUserClasses: i.GetUserSeries = (size) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    dispatch(MeActions.getUserClasses());

    const sizeParam  = size || 50;

    api.get({
      path: `/proxy/zingfit/account/classes?size=${sizeParam}`,
    }).then((series: i.Series[]) => {
      dispatch(MeActions.getUserClassesSuccess(series));
      resolve();
    }).catch((error: i.ZingfitApiError) => {
      const message = getErrorMessage(error);
      dispatch(MeActions.getUserClassesFailed(message));
      reject();
    });
  })
);

