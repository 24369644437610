//WIP: ANY TYPES WILL BE COMPLETED IN THE NEXT TICKET, WHEN WE HAVE LOGIN DETAILS TO THE CLIENT AND CAN FULLY FINISH THE TICKET.
import * as i from 'types';
import { action, ActionType } from 'typesafe-actions';

const POST = 'form/POST';
const POST_SUCCESS = 'form/POST_SUCCESS';
const POST_FAILED = 'form/POST_FAILED';

const RESET = 'form/RESET';

export const FormActions = {
  postForm: () => action(POST),
  postFormSuccess: (res: any) => action(POST_SUCCESS, res),
  postFormFailed: (error: string) => action(POST_FAILED, error),

  resetForm: () => action(RESET),
};

const initialState: i.DuckFormState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export default (state = initialState, action: ActionType<typeof FormActions>) => {
  switch (action.type) {
    case POST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case POST_SUCCESS:
      return {
        ...state,
        error: false,
        loading: true,
        data: action.payload,
      };
    case POST_FAILED:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export const postForm: i.PostForm = (data: i.ComponentFormBlockData, subject: string, isLP?: boolean) => (dispatch, getState, api) => (
  new Promise((resolve, reject) => {
    const textLine = [
      `Form name: ${isLP
        ? `Landing page popup - ${subject}`
        : `Contact form - ${subject} `}`,
      `from: ${data.name}`,
    ];

    if (!isLP) {
      textLine.push(`textmessage: ${data.textareaInput || ''}`);
      textLine.push(`extraInput: ${data.thirdInput || ''}`);
    }

    const message =  {
      'to': `${data.emailaddress || ''}`,
      'subject': `${subject}`,
      'text': textLine.join(', '),
    };

    dispatch(FormActions.postForm());
    api.post({
      path: '/api/v2/contact',
      body: message,
    })
      .then((response: i.FormResponse) => {
        dispatch(FormActions.postFormSuccess(response));
        resolve();
      }).catch((error: string) => {
        dispatch(FormActions.postFormFailed(error));
        reject(error);
      });
  })
);
