import * as i from 'types';
import qs from 'qs';

const PAYMENT_URL = process.env.GATSBY_PAYMENT_URL;
const SITE_URL = process.env.GATSBY_SITE_URL;

type generateUrlPayload = { order?: i.Order, paymentInfo?: i.PaymentInfo, language: string };
type generateUrlResponse = string | null;

const getBaseQueries = ({ order, paymentInfo, language }: Required<generateUrlPayload>) => ({
  origin: 'web',
  locale: 'en',
  firstName: order.billingAddress?.firstName,
  lastName: order.billingAddress?.lastName,
  apiKey: paymentInfo.gateway.stripeKey,
  accountKey: paymentInfo.gateway.stripeAccount,
  orderId: order?.id,
  redirectUrl: `${SITE_URL}/${language}/paymentsuccess`,
});

export const generatePaymentUrl: GeneratePaymentUrl = ({ order, paymentInfo, paymentMethod, language }) => {
  if (!order || !paymentInfo) return null;
  const query = {
    ...getBaseQueries({ order, paymentInfo, language }),
    address: order.billingAddress?.address,
    postcode: order.billingAddress?.zip,
    city: '',
    country: 'Netherlands',
    amount: parseInt(`${Number(order?.amountDue?.amount) * 100}`),
    isExpress: true,
  };

  return `${PAYMENT_URL}/pay/${paymentMethod}?${qs.stringify(query)}`;
};

type GeneratePaymentUrl = (
  payload: generateUrlPayload & { paymentMethod: i.PaymentMethods },
) => generateUrlResponse;

export const generate3DSecureAuthUrl: Generate3DSectureAuthUrl = ({
  order, paymentInfo, paymentIntentClientSecret, language,
}) => {
  if (!order || !paymentInfo) return null;

  const params = qs.stringify({
    ...getBaseQueries({ order, paymentInfo, language }),
    zingKey: paymentInfo.gateway.stripeZingKey,
    isExpress: paymentInfo.gateway.stripeConnectExpress,
    threeDSecureAuth: true,
    paymentIntentClientSecret,
  });

  return `${PAYMENT_URL}/pay/processing?${params}`;
};

type Generate3DSectureAuthUrl = (
  payload: generateUrlPayload & { paymentIntentClientSecret: string },
) => generateUrlResponse;
