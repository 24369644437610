import qs from 'qs';
import { RouteComponentProps } from '@reach/router';
import { isBrowser } from './browser';

export const getPageQueryParams = (location?: RouteComponentProps['location']) => (
  location
    ? qs.parse(location?.search, { ignoreQueryPrefix: true }) as Record<string, any>
    : isBrowser()
      ? qs.parse(window?.location?.search, { ignoreQueryPrefix: true }) as Record<string, any>
      : {}
);
