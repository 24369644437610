import * as i from 'types';
import qs from 'qs';

import { initialState } from 'services/context/authenticationContext';
import { useAuthenticationContext } from 'services/hooks/useAuthenticationContext';
import { Auth, setAuthenticationTokens, removeAuthenticationTokens } from 'services/authentication';

export const useAuthenticationActions = () => {
  const { dispatch } = useAuthenticationContext();

  const login = async (username: string, password: string) => (
    new Promise<i.AuthenticationState>((resolve, reject) => {
      dispatch({ loading: true });

      Auth.post({
        path: '/proxy/zingfit/oauth/login',
        body: {
          username: username.toLowerCase(),
          password: password,
        },
      })
        .then((tokens) => {
          setAuthenticationTokens({
            refresh: tokens.refresh_token,
            access: `${tokens.token_type} ${tokens.access_token}`,
            expires: tokens.expires_in,
          });

          const response = dispatch({
            authenticated: true,
            loading: false,
            error: false,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            loading: false,
            error: true,
          });

          reject(error);
        });
    })
  );

  const logout = () => (
    new Promise<i.AuthenticationState>((resolve, reject) => {
      dispatch({ loading: true });

      removeAuthenticationTokens();
      const response = dispatch({
        ...initialState,
        loading: false,
        authenticated: false,
      });

      resolve(response);
    })
  );

  const register = (data: i.UserRegisterFormData) => (
    new Promise<i.AuthenticationState>((resolve, reject) => {
      dispatch({ loading: true });

      const body = {
        ...data,
      };

      Auth.post({
        path: '/proxy/zingfit/account/register',
        body,
      })
        .then(() => {
          const response = dispatch({
            authenticated: false,
            loading: false,
            error: false,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            loading: false,
            error: true,
          });

          reject(error);
        });
    })
  );

  const requestResetPassword = (email: string) => (
    new Promise<i.AuthenticationState>((resolve, reject) => {
      dispatch({ loading: true });

      Auth.post({
        path: '/proxy/zingfit/account/forgot-password',
        body: {
          username: email,
        },
      })
        .then(() => {
          const response = dispatch({
            authenticated: false,
            loading: false,
            error: false,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            loading: false,
            error: true,
          });

          reject(error);
        });
    })
  );

  const confirmResetPassword = (newPassword: string) => (
    new Promise<i.AuthenticationState>((resolve, reject) => {
      const { token } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

      if (!token) {
        dispatch({ error: true });
        return reject('token are not present in the url.');
      }

      dispatch({ loading: true });

      Auth.post({
        path: '/proxy/zingfit/account/reset-password',
        body: {
          token,
          password: newPassword.trim(),
        },
      })
        .then(() => {
          const response = dispatch({
            authenticated: false,
            loading: false,
            error: false,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            loading: false,
            error: true,
          });

          reject(error);
        });
    })
  );

  return {
    login,
    logout,
    register,
    requestResetPassword,
    confirmResetPassword,
  };
};
