import qs from 'qs';
import * as i from 'types';

import config from './config';

export const generateOptions: i.GenerateOptions = ({
  method, path, query, body, file = false, json = true,
  error, withAuth = config.defaultWithAuth, headers = {},
}) => ({
  path: `${config.apiUrl}${path}${query ? `?${qs.stringify(query, { encode: false })}` : ''}`,
  options: {
    method,
    headers,
    ...(body ? { body } : {}),
  },
  file,
  json,
  errorConfig: error,
  withAuth,
});
